import { ApartmentInterface } from "@/interfaces/ApiInterface";
import ApiModel from "@/models/ApiModel";

export default class ApartmentModel
  extends ApiModel<ApartmentInterface>
  implements ApartmentInterface
{
  name = "";
  address_street = "";
  address_city = "";
  address_postal_code = "";
  address_country = "";
  color = "";
  created_at = "";
  updated_at = "";
  protected modelName = "apartments";

  constructor(data?: ApartmentInterface) {
    super();
    this.set(data);
  }
}
