import { GuestInterface } from "@/interfaces/ApiInterface";
import ApiModel from "@/models/ApiModel";

export default class GuestModel
  extends ApiModel<GuestInterface>
  implements GuestInterface
{
  first_name = "";
  last_name = "";
  full_name = "";
  email = "";
  phone_number = "";
  nb_bookings = 0;
  created_at = "";
  updated_at = "";
  protected modelName = "guests";

  constructor(data?: GuestInterface) {
    super();
    this.set(data);
  }

  get guestInitials(): string {
    return (
      this.first_name.charAt(0).toUpperCase() +
      this.last_name.charAt(0).toUpperCase()
    );
  }
}
