import Model from "@/models/Model";
import authHeader from "@/services/auth-header";
import ApiConnection from "@/services/ApiConnection";

export default abstract class ApiModel<T> extends Model<T> {
  public uuid: string | null = null;
  protected abstract modelName: string;
  private loading = false;
  private api = new ApiConnection();

  public get isLoading() {
    return this.loading;
  }

  public get isPermanent() {
    return !!this.uuid;
  }

  public load() {
    if (!this.uuid) return Promise.reject();
    this.loading = true;
    return this.api.axios
      .get(`${this.modelName}/${this.uuid}`, {
        headers: authHeader(),
      })
      .then((response: { data: T | undefined }) => this.set(response.data))
      .finally(() => (this.loading = false));
  }

  public delete() {
    if (!this.uuid) return Promise.reject();
    this.loading = true;
    return this.api.axios
      .delete(`${this.modelName}/${this.uuid}`, {
        headers: authHeader(),
      })
      .then((response: { data: T | undefined }) => this.set(response.data))
      .finally(() => (this.loading = false));
  }

  public save() {
    this.loading = true;
    if (this.uuid) {
      return this.api.axios
        .put(`${this.modelName}/${this.uuid}`, this, {
          headers: authHeader(),
        })
        .then((response: { data: T | undefined }) => this.set(response.data))
        .finally(() => (this.loading = false));
    }
    return this.api.axios
      .post(`${this.modelName}`, this, { headers: authHeader() })
      .then((response: { data: T | undefined }) => this.set(response.data))
      .finally(() => (this.loading = false));
  }

  public clear() {
    return this.load();
  }
}
