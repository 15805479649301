import { BookingInterface } from "@/interfaces/ApiInterface";
import ApiModel from "@/models/ApiModel";

export default class BookingModel
  extends ApiModel<BookingInterface>
  implements BookingInterface
{
  apartment = null;
  guest = null;
  apartment_uuid = "";
  guest_uuid = "";
  nb_persons = 1;
  price = 0;
  start_at = "";
  end_at = "";
  information = "";
  booking_status: "pending" | "success" | "error" = "pending";
  payment_status: "pending" | "success" | "error" = "pending";
  created_at = "";
  updated_at = "";
  protected modelName = "bookings";

  constructor(data?: BookingInterface) {
    super();
    this.set(data);
  }

  getNbNights(): number {
    const start = new Date(this.start_at);
    const end = new Date(this.end_at);
    return (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);
  }

  getNbWeeks() {
    return Math.ceil(this.getNbNights() / 7);
  }
}
