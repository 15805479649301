import { AxiosResponse } from "axios";
import ApiService from "@/services/ApiService";
import { ProfileInterface } from "@/interfaces/ApiInterface";
import UserModel from "@/models/UserModel";
import ApiConnection from "@/services/ApiConnection";

class AuthService extends ApiConnection {
  login({ email, password }: ProfileInterface): Promise<UserModel> {
    return this.axios
      .post("login", { email, password })
      .then((response: AxiosResponse) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return ApiService.getProfile();
      });
  }

  logout() {
    return this.axios.get("logout").then(() => {
      localStorage.removeItem("user");
    });
  }

  register({ email, password }: ProfileInterface) {
    return this.axios.post("signup", { email, password });
  }
}

export default new AuthService();
