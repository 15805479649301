import axios, { AxiosInstance } from "axios";

const API_URL = "api/";
export default class ApiConnection {
  axios: AxiosInstance;
  constructor() {
    this.axios = axios.create({
      baseURL: API_URL,
    });
  }

  public getUrl(path: string): URL {
    return new URL(
      path,
      new URL(this.axios.defaults.baseURL ?? "", window.location.toString())
    );
  }
}
