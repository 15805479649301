import Model from "@/models/Model";
import { ProfileInterface } from "@/interfaces/ApiInterface";

export default class UserModel
  extends Model<ProfileInterface>
  implements ProfileInterface
{
  email = "";
  password = "";
  avatar_path = "";
  created_at = "";
  first_name = "";
  last_name = "";
  updated_at = "";
  uuid: string | null = null;

  constructor(data?: ProfileInterface) {
    super();
    this.set(data);
  }

  get userInitials(): string {
    return (
      this.first_name.charAt(0).toUpperCase() +
      this.last_name.charAt(0).toUpperCase()
    );
  }
}
