import authHeader from "./auth-header";
import {
  ApartmentInterface,
  BookingInterface,
  EventInterface,
  GuestInterface,
  ReportInterface,
} from "@/interfaces/ApiInterface";
import BookingModel from "@/models/BookingModel";
import GuestModel from "@/models/GuestModel";
import ApartmentModel from "@/models/ApartmentModel";
import UserModel from "@/models/UserModel";
import ApiConnection from "@/services/ApiConnection";

class ApiService extends ApiConnection {
  getProfile(): Promise<UserModel> {
    return this.axios
      .get(`profile`, { headers: authHeader() })
      .then((response) => new UserModel(response.data));
  }

  getGuests(): Promise<GuestModel[]> {
    return this.axios
      .get(`guests`, { headers: authHeader() })
      .then((response) =>
        response.data.map((item: GuestInterface) => new GuestModel(item))
      );
  }

  getBookings(): Promise<BookingModel[]> {
    return this.axios
      .get(`bookings`, { headers: authHeader() })
      .then((response) =>
        response.data.map((item: BookingInterface) => new BookingModel(item))
      );
  }

  getCalendar(start: Date, end: Date): Promise<EventInterface[]> {
    return this.axios
      .get(`calendar`, {
        params: { start, end },
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  getApartments(): Promise<ApartmentModel[]> {
    return this.axios
      .get(`apartments`, { headers: authHeader() })
      .then((response) =>
        response.data.map(
          (item: ApartmentInterface) => new ApartmentModel(item)
        )
      );
  }

  getReports(year: number): Promise<ReportInterface[]> {
    return this.axios
      .get(`reports/${year}`, { headers: authHeader() })
      .then((response) => response.data);
  }

  getICalUrl(): string {
    const url = this.getUrl("ical");
    url.protocol = "webcal";
    return url.toString();
  }
}

export default new ApiService();
