import Vue from "vue";
import Vuetify from "vuetify";
import fr from "vuetify/src/locale/fr";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { fr },
    current: "fr",
  },
  icons: {
    iconfont: "fa",
  },
  theme: {
    dark: false,
  },
});
