type Instantiable = { new (...args: unknown[]): unknown };
export default class Model<T> {
  protected get class(): Instantiable {
    return this.constructor as Instantiable;
  }

  public set(data?: T) {
    if (data) {
      for (const [key, value] of Object.entries(data) as [keyof T, never][]) {
        if (key in this) (this as unknown as T)[key] = value;
      }
    }
  }

  public clear() {
    const c = new this.class() as T;
    for (const [key] of Object.entries(this) as [keyof T, never][]) {
      if (key in this) {
        (this as unknown as T)[key] = c[key];
      }
    }
  }
}
