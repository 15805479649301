import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import AuthService from "../services/AuthService";
import { ProfileInterface } from "@/interfaces/ApiInterface";
import ApiService from "@/services/ApiService";
import UserModel from "@/models/UserModel";

@Module({
  namespaced: true,
})
export default class AuthModule extends VuexModule {
  public loggedIn = false;

  public user: UserModel | null = null;

  @Mutation setLoginSuccess(user: UserModel) {
    this.loggedIn = true;
    this.user = user;
  }

  @Mutation setLoginFailure() {
    this.loggedIn = false;
    this.user = null;
  }

  @Mutation setLogout() {
    this.loggedIn = false;
    this.user = null;
  }

  @Mutation setRegisterSuccess() {
    this.loggedIn = false;
  }

  @Mutation registerFailure() {
    this.loggedIn = false;
  }

  @Action({ rawError: true }) login(user: ProfileInterface) {
    return AuthService.login(user).then(
      (user) => {
        this.context.commit("setLoginSuccess", user);
        return Promise.resolve(user);
      },
      (error) => {
        this.context.commit("setLoginFailure");
        return Promise.reject(error);
      }
    );
  }

  @Action({ rawError: true }) logout() {
    console.log("logout");
    this.context.commit("setLogout");
    return AuthService.logout();
  }

  @Action({ rawError: true }) register(user: ProfileInterface) {
    return AuthService.register(user).then(
      (response) => {
        this.context.commit("setRegisterSuccess");
        return Promise.resolve(response.data);
      },
      (error) => {
        this.context.commit("setRegisterFailure");
        return Promise.reject(error);
      }
    );
  }

  @Action({ rawError: true }) autoConnect() {
    if (!JSON.parse(localStorage.getItem("user") as string)) return;
    return ApiService.getProfile().then((user) => {
      this.context.commit("setLoginSuccess", user);
    });
  }
}
